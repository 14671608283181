<template>  
  <section class="services">  
    <h2>Our Services</h2>  
    <div class="service-cards">  
      <div class="service-card" v-for="service in services" :key="service.id">  
        <img :src="service.image" :alt="service.title" />  
        <h3>{{ service.title }}</h3>  
        <p>{{ service.description }}</p>  
      </div>  
    </div>  
  </section>  
</template>  
  
<script>  
export default {  
  name: 'ServicesSection',  
  data() {  
    return {  
      services: [  
        {  
          id: 1,  
          title: 'Petrol station 1',  
          description: 'Building quality stations customized for you.',  
          image: '@/assets/residential.jpg',  
        },  
        {  
          id: 2,  
          title: 'Petrol Station 2',  
          description: 'Professional groundworks for your service station.',  
          image: '@/assets/commercial.jpg',  
        },  
        // Add more services as needed  
      ],  
    };  
  },  
};  
</script>  
  
<style scoped>  
.services {  
  padding: 2rem;  
  /* Add additional styling */  
}  
.service-cards {  
  display: flex;  
  gap: 2rem;  
  /* Flex wrap for responsiveness */  
  flex-wrap: wrap;  
}  
.service-card {  
  flex: 1 1 calc(33% - 2rem);  
  /* Adjust based on desired columns */  
}  
</style>  