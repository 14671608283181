
<template>
  <v-container>
    <div class="custom-carousel">
      <div v-for="(logo, index) in visibleLogos"
        :key="index"
        class="carousel-item"
        :class="{ active: index === 1 }"
      >
        <v-img :src="logo" contain class="carousel-image"></v-img>
      </div>
    </div>
  </v-container>
</template>

<script>
import burgerKing from '@/assets/logos/burgerking.jpg';
import egGroup from '@/assets/logos/eurogarages.png';
import greggs from '@/assets/logos/greggs.png';
import popEyes from '@/assets/logos/popeyes.png';
import kfc from '@/assets/logos/kfc.png';

export default {
  data() {
    return {
      companyLogos: [
        burgerKing,
        egGroup,
        greggs,
        popEyes,
        kfc,
      ],
      currentIndex: 0, // The starting index of the carousel
    };
  },
  computed: {
    visibleLogos() {
      // Show 3 logos at a time, wrapping around the array
      const total = this.companyLogos.length;
      return [
        this.companyLogos[this.currentIndex % total],
        this.companyLogos[(this.currentIndex + 1) % total],
        this.companyLogos[(this.currentIndex + 2) % total],
      ];
    },
  },
  mounted() {
    // Auto-rotate the carousel every 3 seconds
    setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.companyLogos.length;
    }, 3000); // Change every 3 seconds
  },
};
</script>

<style scoped>
.custom-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 200px; /* Adjust the height as needed */
}

.carousel-item {
  flex: 0 0 33.33%; /* Show 3 items side-by-side */
  transform: scale(0.8); /* Shrink inactive items */
  transition: transform 0.5s, opacity 0.5s;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-item.active {
  transform: scale(1); /* Highlight the center item */
  opacity: 1;
}

.carousel-image {
  max-height: 150px; /* Adjust image size */
  object-fit: contain;
}
</style>
