<template>
  <v-container class="pa-0" fluid>
    <!-- Hero Section with Slim YouTube Video -->
    <div class="video-section">
      <!-- YouTube Video Embed -->
      <div class="video-container">
        <iframe
        src="https://www.youtube-nocookie.com/embed/2-5Kng4_rJ8?si=ikPeR1T7PQF0KMvz?start=06&autoplay=1"
        title="D&E Groundworks - Drone Footage"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen></iframe>
      </div>

      <!-- Overlay Text -->
      <div class="overlay-text">
        <h1 class="display-2 font-weight-bold text-white">Building Your Future, Today</h1>
        <p class="text-h5 text-white mb-6">Professional groundwork services you can trust.</p>
        <v-btn color="accent" large class="elevation-2" router-link to="/projects">Learn More</v-btn>
      </div>
    </div>
    <AboutSection />
    <ContactSection/>
    <WhoWeWorkWith/>
    <ServicesSection/>
    <ProjectsSection/>
    <MapPin/>
  </v-container>
</template>

<script>
export default {};
</script>

<script setup>
import AboutSection from '@/components/AboutSection.vue';
import ProjectsSection from '@/components/ProjectsSection.vue';
import ServicesSection from '@/components/ServicesSection.vue';
import ContactSection from '@/components/ContactSection.vue';
import WhoWeWorkWith from '@/components/WorkWith.vue'
import MapPin from '@/components/MapPin.vue'
</script>

<style scoped>
/* Full-width video with slim height */
.video-section {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust the height to make it slimmer */
  overflow: hidden;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.video-container iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; /* Ensure the iframe spans the full width */
  height: 100%; /* Match the parent height */
}

/* Overlay text styling */
.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  background: rgba(0, 0, 0, 0.5); /* Optional: darken the background */
  padding: 20px 40px;
  border-radius: 8px;
}

.text-white {
  color: white;
}

.overlay-text v-btn:hover {
  background-color: white !important;
  color: #000 !important;
}

@media (max-width: 600px) {
  .video-section {
    height: 250px; /* Slimmer for mobile screens */
  }
}

</style>
