<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>Our Project Locations</v-card-title>
            <v-card-text>
              <div ref="map" style="height: 500px;"></div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import L from 'leaflet';
  import 'leaflet/dist/leaflet.css';
  
  export default {
    name: 'ProjectShowcaseMap',
    data() {
      return {
        projects: [
          {
            id: 1,
            name: 'Project A',
            latitude: 53.3498,
            longitude: -6.2603,
            url: '#'
          },
          {
            id: 2,
            name: 'Project B',
            latitude: 51.5074,
            longitude: -0.1278,
            url: '#'
          },
          // Add more project objects as needed
        ],
        map: null
      }
    },
    mounted() {
      this.initMap();
      this.addMarkers();
    },
    methods: {
      initMap() {
        this.map = L.map(this.$refs.map).setView([53, -6], 5);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);
      },
      addMarkers() {
  this.projects.forEach(project => {
    L.marker([project.latitude, project.longitude])
      .addTo(this.map)
      .bindPopup(`<a href="${project.url}" target="_blank">${project.name}</a>`);
  });
}
    }
  }
  </script>