<template>  
    <section class="projects">  
      <h2>Our Projects</h2>  
      <div class="project-gallery">  
        <div class="project-card" v-for="project in projects" :key="project.id">  
          <img :src="project.image" :alt="project.name" />  
          <h3>{{ project.name }}</h3>  
          <p>{{ project.description }}</p>  
        </div>  
      </div>  
    </section>  
  </template>  
    
  <script>  
  export default {  
    name: 'OurProjects',  
    data() {  
      return {  
        projects: [  
          {  
            id: 1,  
            name: 'Petrol station',  
            description: 'A modern fuel station in the city center.',  
            image: '@/assets/hero-image.jpg',  
          },  

        ],  
      };  
    },  
  };  
  </script>  
    
  <style scoped>  
  .projects {  
    padding: 2rem;  
  }  
  .project-gallery {  
    display: flex;  
    flex-wrap: wrap;  
    gap: 2rem;  
  }  
  .project-card {  
    flex: 1 1 calc(50% - 2rem);  
  }  
  </style>