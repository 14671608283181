<template>
  <v-footer app color="grey-lighten-2" dark>
    <v-col class="text-center">
      &copy; 2024 D&E Groundworks. All rights reserved.
    </v-col>
  </v-footer>
</template>
    
  <script>  
  export default {  
    name: 'FooterComponent',  
  };  
  </script>  
    
  <style scoped>  
  .footer {  
    background-color: #333;  
    color: #fff;  
    padding: 1rem 2rem;  
  }  
  .footer-content {  
    display: flex;  
    justify-content: space-between;  
    align-items: center;  
  }  
  .social-links {  
    list-style: none;  
    display: flex;  
    gap: 1rem;  
  }  
  .social-links li a {  
    color: #fff;  
  }  
  </style>  