<template>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card elevation="2" class="pa-5">
            <v-card-title>
              <h2 class="text-h4 about">About Us</h2>
            </v-card-title>
            <v-card-subtitle>
              <p>
                Established 10 years ago, <strong>D&E Groundworks</strong> specializes in groundworks for petrol forecourts and retail developments like McDonald's, Starbucks, and Popeyes.<br/>We work primarily with Eurogarages, delivering high-quality, efficient groundworks solutions.
              </p>
            </v-card-subtitle>
            <v-card-text>
              <p>
                From excavation to drainage and paving, we handle all aspects of groundworks with precision and safety. Our experienced team ensures projects are completed on time and within budget, meeting the unique demands of each site.
              </p>
              <p>
                We're committed to continuous improvement, maintaining high standards and building long-term relationships with our clients across the UK.
              </p>
            </v-card-text>
                  <v-btn text to="/about" class="about">Learn More</v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>

  <style scoped>
  .about {
    padding: 40px 20px;
    text-align: center;
    background-color: #f6f6f6;
  }
  .about h2 {
    font-size: 2rem;
    color: #009fe3;
  }
  .about button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #009fe3;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  </style>
  