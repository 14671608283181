<template>
  <v-app-bar app :elevation="24" color="grey-lighten-2"  scroll-behavior="elevate">

    <v-toolbar-title class="d-flex align-center">
    <img
      :src="require('@/assets/logo.png')"
      alt="D&E Groundworks Logo"
      :width="186"
    />
  </v-toolbar-title>

    <v-spacer></v-spacer>
    <v-btn text to="/">Home</v-btn>
    <v-btn text to="/about">About</v-btn>
    <v-btn text to="/services">Services</v-btn>
    <v-btn text to="/projects">Projects</v-btn>
    <v-btn text to="/contact">Contact</v-btn>
  </v-app-bar>
</template>

    
  <script>  
  export default {  
    name: 'NavbarComponent',  
  };  
  </script>  
    