<template>

<v-alert
      v-if="alert.visible"
      :type="alert.type"
      dismissible
      class="mb-4"
    >
      {{ alert.message }}
    </v-alert>

  <v-container>
    <v-form ref="form" v-model="valid">
      <v-text-field
        v-model="name"
        label="Your Name"
        :rules="[rules.required]"
        required
      ></v-text-field>
      <v-text-field
        v-model="email"
        label="Your Email"
        :rules="[rules.email, rules.required]"
        required
      ></v-text-field>
      <v-textarea
        v-model="message"
        label="Your Message"
        :rules="[rules.required]"
        required
      ></v-textarea>
      <v-btn :disabled="!valid" @click="submitForm">Submit</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      valid: false,
      name: "",
      email: "",
      message: "",
      alert: {
        visible: false,
        message: "",
        type: "", // success, error, etc.
      },
      rules: {
        required: value => !!value || "Required.",
        email: value => /.+@.+\..+/.test(value) || "E-mail must be valid."
      }
    };
  },
  methods: {
    async submitForm() {
      if (this.$refs.form.validate()) {
        try {
          await axios.post(
          "https://emailfunction-e6lm53u2sq-uc.a.run.app/api/contact",
          {
            name: this.name,
            email: this.email,
            message: this.message,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

          // Show success alert
          this.showAlert("Message sent successfully!", "success");

          // Clear the form
          this.name = "";
          this.email = "";
          this.message = "";
          this.valid = false;
        } catch (error) {
          console.error("Error sending message:", error);

          // Show error alert
          this.showAlert(
            "An error occurred while sending the message. Please try again.",
            "error"
          );
        }
      }
    },
    showAlert(message, type) {
      this.alert.message = message;
      this.alert.type = type;
      this.alert.visible = true;

      // Automatically hide the alert after 5 seconds
      setTimeout(() => {
        this.alert.visible = false;
      }, 5000);
    },
  },
};
</script>


    
  <style scoped>  
  .contact {  
    padding: 2rem;  
  }  
  form {  
    display: flex;  
    flex-direction: column;  
  }  
  label {  
    margin-bottom: 1rem;  
  }  
  button {  
    align-self: flex-start;  
  }  
  </style>  