<template>
  <v-container>
    <v-row class="mb-5">
      <v-col cols="12">
        <v-alert type="info" elevation="2">
          <h2 class="text-h4">About Us</h2>
          <p>
            Established 10 years ago, <strong>D&E Groundworks</strong> has built a reputation as one of the leading specialists in groundworks for the UK’s retail and commercial sectors. With a particular focus on petrol forecourts, we pride ourselves on delivering high-quality groundwork services that ensure safety, efficiency, and long-term durability. Over the years, we have successfully completed a wide range of projects for major clients, including Eurogarages, McDonald's, Starbucks, and Popeyes.
          </p>
          <p>
            Our expertise lies in providing tailored solutions for the complex demands of modern retail developments. From site preparation and excavation to drainage installation and paving, we handle every aspect of the groundworks process with the utmost precision. Whether it's creating a safe and functional petrol forecourt or preparing a site for a fast-food chain, we are committed to meeting the unique requirements of each project.
          </p>
          <p>
            At <strong>D&E Groundworks</strong>, we take pride in our ability to work to the highest standards, on time and within budget. Our experienced team of professionals ensures that all projects are completed safely and efficiently, with minimal disruption to your operations.
          </p>
          <p>
            As a company, we are dedicated to continuous improvement and are always exploring new ways to enhance the quality of our work. Our passion for groundworks and commitment to customer satisfaction has made us a trusted partner for clients across the UK.
          </p>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AboutUsPage"
};
</script>

<style scoped>
.about-us {
  padding: 20px;
  background-color: #f4f4f4;
}
</style>
