import { createRouter, createWebHistory } from 'vue-router';  
import HomeView from '@/views/HomeView.vue';  
//import ServicesView from '@/views/ServicesView.vue';  
import OurProjects from '@/views/OurProjects.vue';  
import AboutView from '@/views/AboutView.vue';  
import ContactView from '@/views/ContactView.vue';  
  
const routes = [  
  { path: '/', name: 'Home', component: HomeView },  
  //{ path: '/services', name: 'Services', component: ServicesView },  
  { path: '/projects', name: 'Projects', component: OurProjects },  
  { path: '/about', name: 'About', component: AboutView },  
  { path: '/contact', name: 'Contact', component: ContactView },  
];  
  
const router = createRouter({  
  history: createWebHistory(),  
  routes,  
});  
  
export default router;  