// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    themes: {
      light: {
        primary: '#3b82f6', // Replace with your logo's main color
        secondary: '#64748b',
        accent: '#facc15',
        background: '#f3f4f6',
      },
    },
  },
});